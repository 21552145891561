// Inside Edge is a weekly summary of random charts and happenings in the world
// It lives on Bogger, a third party blogging system and is pulled in visually to the Edgepoint site
// We have no access to the content but can force some formatting, however testing is hard
import React from 'react';
import { graphql, PageProps } from 'gatsby';
import styled from 'styled-components';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import { ArrowBack, ArrowForward, ChevronRight } from '@material-ui/icons';
import {
  Blogger__Post,
  ContentfulComponentSiteFooter,
  ContentfulComponentSiteHeader,
  ContentfulPageLanding,
  InsideEdgeTemplateQuery,
} from '../../graphql-types';
import { WEBSITE } from '../types/website.enum';
import edgepointTheme from '../themes/edgepoint';
import cymbriaTheme from '../themes/cymbria';
import Layout from '../components/Layout';
import { PageContext } from '../types/pageContext';
import Footer from '../components/Footer';
import Header from '../components/Header';
import SEO from '../components/SEO';
import Typography from '../components/Typography';
import Divider from '../components/Divider';
import { useGlobalState } from '../hooks/useGlobalState';
import SearchInsideEdge from '../components/SearchInsideEdge';
import ComponentSelector from '../components/ComponentSelector';
import CookiePreferences from '../components/CookiePreferences';

type InsideEdgeBloggerPost = Blogger__Post & { year: string };

const InsideEdgeSideColumn = styled.div`
  height: 100%;
  // position: absolute;
  // left: 0;
`;

const InsideEdgeSideColumnSticky = styled.div`
  position: sticky;
  top: 0;
`;

const BloggerContainer = styled.div`
  * {
    float: none !important; // NOTHING SHOULD FLOAT!
  }

  h1 {
    font-family: ${(props) => props.theme.fontFamily.heading} !important;
    font-size: ${(props) => props.theme.fontSize.h1} !important;
    font-weight: ${(props) => props.theme.fontWeight.medium} !important;
    line-height: ${(props) => props.theme.lineHeight.xs} !important;
    text-transform: uppercase !important;
  }
  h2 {
    font-family: ${(props) => props.theme.fontFamily.heading} !important;
    font-size: ${(props) => props.theme.fontSize.h2} !important;
    font-weight: ${(props) => props.theme.fontWeight.medium} !important;
    line-height: ${(props) => props.theme.lineHeight.xxs} !important;
  }
  h3 {
    font-family: ${(props) => props.theme.fontFamily.heading} !important;
    font-size: ${(props) => props.theme.fontSize.h3} !important;
    font-weight: ${(props) => props.theme.fontWeight.semiBold} !important;
    line-height: ${(props) => props.theme.lineHeight.xs} !important;
  }
  h4 {
    font-family: ${(props) => props.theme.fontFamily.heading} !important;
    font-size: ${(props) => props.theme.fontSize.h4} !important;
    font-weight: ${(props) => props.theme.fontWeight.medium} !important;
    line-height: ${(props) => props.theme.lineHeight.xs} !important;
  }
  h5 {
    font-family: ${(props) => props.theme.fontFamily.heading} !important;
    font-size: ${(props) => props.theme.fontSize.h4} !important;
    font-weight: ${(props) => props.theme.fontWeight.medium} !important;
    line-height: ${(props) => props.theme.lineHeight.xs} !important;
  }
  h6 {
    font-family: ${(props) => props.theme.fontFamily.heading} !important;
    font-size: ${(props) => props.theme.fontSize.h4} !important;
    font-weight: ${(props) => props.theme.fontWeight.medium} !important;
    line-height: ${(props) => props.theme.lineHeight.xs} !important;
  }
  div,
  p {
    &:not(:first-of-type) {
      margin-top: ${(props) => props.theme.spacing.m1} !important;
    }

    font-family: ${(props) => props.theme.fontFamily.body} !important;
    font-size: ${(props) => props.theme.fontSize.body} !important;
    font-weight: ${(props) => props.theme.fontWeight.light} !important;
    line-height: ${(props) => props.theme.lineHeight.lg} !important;
  }
  img {
    display: block;
    margin-top: ${(props) => props.theme.spacing.s4};
  }
  a,
  b,
  span {
    // override any blogger inserted span changes
    font-family: inherit !important;
    font-size: inherit !important;
  }
  a {
    display: inline-block !important;
    text-decoration: underline !important;
    margin: 0px !important;
  }
  ul,
  ol {
    display: block;
    margin: 1em 0;
    padding: 0 0 0 40px;
  }
  ol {
    list-style-type: decimal !important;
    & ol {
      margin-top: 12px !important;
      list-style-type: lower-latin !important;
      & ol {
        list-style-type: lower-roman !important;
      }
    }
  }
  ul {
    list-style-type: disc !important;
    & ul {
      margin-top: 12px !important;
      list-style-type: circle !important;
      & ul {
        list-style-type: square !important;
      }
    }
  }
  li {
    display: list-item;
    &:not(:first-of-type) {
      margin-top: ${(props) => props.theme.spacing.s2};
    }
  }

  // hide all empty text tags
  div:empty,
  p:empty,
  b:empty,
  span:empty {
    display: none !important;
  }

  & > *:first-child {
    display: block !important;
    font-family: ${(props) => props.theme.fontFamily.heading} !important;
    font-size: ${(props) => props.theme.fontSize.h4} !important;
    font-weight: ${(props) => props.theme.fontWeight.medium} !important;
    line-height: ${(props) => props.theme.lineHeight.xs} !important;
    padding-bottom: ${(props) => props.theme.spacing.s1};
    text-decoration: none !important;
  }
`;

export const query = graphql`
  query InsideEdgeTemplate($id: String!, $node_locale: String!) {
    contentfulComposePage(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      title
      shortDescription {
        shortDescription
      }
      seo {
        ...ContentfulComposeSeoFragment
      }
      content {
        ... on ContentfulPageLanding {
          sections {
            ... on ContentfulComponentFlexible {
              ...ContentfulComponentFlexibleFragment
            }
          }
        }
      }
      website {
        ...WebsiteFragment
      }
      pageHeader {
        ...ContentfulComponentSiteHeaderFragment
      }
      pageFooter {
        ...ContentfulComponentSiteFooterFragment
      }
    }
    allBloggerPost(sort: { order: DESC, fields: published }) {
      edges {
        node {
          slug
          kind
          id
          blog {
            id
          }
          labels
          published(formatString: "dddd, MMMM D, y")
          year: published(formatString: "y")
          updated
          url
          selfLink
          title
          titleLink
          content
          author {
            id
            displayName
            url
            image {
              url
            }
          }
          replies {
            totalItems
            selfLink
          }
        }
      }
    }
  }
`;
const Page: React.FC<PageProps<InsideEdgeTemplateQuery, PageContext>> = (
  props,
) => {
  const { data, location } = props;
  const { contentfulComposePage, allBloggerPost } = data;
  const { locale } = useLocalization();
  const { site } = useGlobalState();

  const limit = 5;
  const [currentSkip, setCurrentSkip] = React.useState(0);
  const [currentQuery, setCurrentQuery] = React.useState('');
  const [currentYear, setCurrentYear] = React.useState('');
  const [currentInsideEdgePosts, setCurrentInsideEdgePosts] = React.useState<
    InsideEdgeBloggerPost[]
  >([]);
  const [archiveYears, setArchiveYears] = React.useState<string[]>([]);

  const cookiePreferences = contentfulComposePage?.website?.cookiePreferences;

  const pageHeader =
    (contentfulComposePage?.pageHeader as unknown as ContentfulComponentSiteHeader) ??
    (contentfulComposePage?.website
      ?.header as unknown as ContentfulComponentSiteHeader);

  const pageFooter =
    (contentfulComposePage?.pageFooter as unknown as ContentfulComponentSiteFooter) ??
    (contentfulComposePage?.website
      ?.footer as unknown as ContentfulComponentSiteFooter);

  React.useEffect(() => {
    // collect years for archive
    const years = [];

    // set initial inside edge posts
    const insideEdgePosts = allBloggerPost?.edges
      ?.map(({ node }) => {
        // we can determine how many years we need to display for archives here
        // they should be already ordered for us from graphql sort
        if (years.indexOf(node.year) === -1) {
          years.push(node.year);
        }

        return node;
      })
      ?.splice(0, limit) as InsideEdgeBloggerPost[];
    setCurrentInsideEdgePosts(insideEdgePosts);
    setArchiveYears(years);
  }, []);

  React.useEffect(() => {
    // update when url parameters change
    const params = new URLSearchParams(location.search);

    const skip = params.get('skip');
    if (skip) {
      setCurrentSkip(parseInt(skip));
    } else {
      setCurrentSkip(0);
      // we need this so that going back to base inside-edge page will
      // reset to the initial list of posts
      const newInsideEdgePosts = allBloggerPost?.edges
        ?.map(({ node }) => node)
        ?.splice(0, limit) as InsideEdgeBloggerPost[];
      setCurrentInsideEdgePosts(newInsideEdgePosts);
    }

    const q = params.get('q');
    if (q && q.length >= 3) {
      setCurrentQuery(q);
    } else {
      setCurrentQuery('');
    }

    const year = params.get('year');
    if (year) {
      setCurrentYear(year);
    } else {
      setCurrentYear('');
    }
  }, [location.search]);

  React.useEffect(() => {
    // update shown articles when skip changes
    const newInsideEdgePosts = allBloggerPost?.edges
      ?.map(({ node }) => node)
      ?.splice(currentSkip, limit) as InsideEdgeBloggerPost[];
    setCurrentInsideEdgePosts(newInsideEdgePosts);
  }, [currentSkip]);

  React.useEffect(() => {
    if (currentYear) {
      // update shown articles when year changes
      const newInsideEdgePosts = allBloggerPost?.edges
        ?.map(({ node }) => node)
        ?.filter(
          (post) => post.year.indexOf(currentYear) > -1,
        ) as InsideEdgeBloggerPost[];
      setCurrentInsideEdgePosts(newInsideEdgePosts);
    }
  }, [currentYear]);

  React.useEffect(() => {
    if (currentQuery) {
      // update shown articles when query changes
      const newInsideEdgePosts = allBloggerPost?.edges
        ?.map(({ node }) => node)
        ?.filter((post) => post.content.indexOf(currentQuery) > -1)
        ?.splice(currentSkip, limit) as InsideEdgeBloggerPost[];
      setCurrentInsideEdgePosts(newInsideEdgePosts);
    }
  }, [currentQuery]);

  // strongly type content prop
  const content =
    contentfulComposePage?.content as unknown as ContentfulPageLanding;

  const insideEdgeArchivesElement = (
    <>
      <Typography variant="body" className="font-medium">
        Archive
      </Typography>
      <Divider fullWidth color="black" className="mt-s1" />
      <div className="mt-s1">
        {archiveYears.map((year) => {
          return (
            <div key={year}>
              {currentYear === year ? (
                <span className="font-medium">{year}</span>
              ) : (
                <LocalizedLink
                  language={locale}
                  to={`${location.pathname}/?year=${year}`}
                  className="flex items-center"
                >
                  <span>{year}</span>
                  <ChevronRight className="text-secondary ml-s1" />
                </LocalizedLink>
              )}
            </div>
          );
        })}
      </div>
    </>
  );

  const insideEdgeSearchSignUpElement = (
    <>
      <SearchInsideEdge />

      <div className="mt-m3">
        {content?.sections?.map((contentfulComponent, index) => {
          return (
            <ComponentSelector
              key={`${contentfulComponent.id}-${index}`}
              contentfulComponent={contentfulComponent}
              className="text-left"
            />
          );
        })}
      </div>
    </>
  );

  const mobileMenuPaddingClasses = 'pt-m4 lg:pt-0';

  return (
    <Layout theme={site === WEBSITE.EDGEPOINT ? edgepointTheme : cymbriaTheme}>
      <SEO data={contentfulComposePage?.seo} location={location} />
      <Header
        data={pageHeader}
        location={location}
        logo={contentfulComposePage?.website?.siteAssets?.logo}
        mobileLogo={contentfulComposePage?.website?.siteAssets?.mobileLogo}
      />
      <main id="main" className={`${mobileMenuPaddingClasses}`}>
        <div className="container py-s4 lg:py-m3 lg:grid lg:grid-cols-10 lg:gap-x-s3 lg:grid-flow-row-dense">
          <div className="lg:col-span-7">
            <div className="text-center lg:text-left">
              {contentfulComposePage?.title && (
                <Typography as="h2" variant="h1">
                  {contentfulComposePage?.title}
                </Typography>
              )}
              {contentfulComposePage?.shortDescription?.shortDescription && (
                <Typography as="p" variant="body" className="mt-s2">
                  {contentfulComposePage?.shortDescription?.shortDescription}
                </Typography>
              )}
            </div>

            <div className="relative -mx-s3 mt-m2 bg-greyLight py-s4 px-s3 lg:hidden">
              {insideEdgeSearchSignUpElement}
            </div>

            {currentInsideEdgePosts ? (
              currentInsideEdgePosts.map((bloggerPost) => {
                return (
                  <div
                    key={`blogger-post-${bloggerPost.id}`}
                    className="mt-m2 first:mt-0"
                  >
                    <Divider
                      fullWidth
                      className="hidden lg:block mt-s1 mb-s4"
                    />
                    {bloggerPost.published && (
                      <Typography as="time" variant="h3" className="block">
                        {bloggerPost.published}
                      </Typography>
                    )}
                    {bloggerPost.author?.displayName && (
                      <Typography
                        as="div"
                        variant="body"
                        className="font-medium mt-s1"
                      >
                        By {bloggerPost.author?.displayName}
                      </Typography>
                    )}

                    {bloggerPost.content && (
                      <BloggerContainer
                        className="mt-m2"
                        dangerouslySetInnerHTML={{
                          __html: bloggerPost.content,
                        }}
                      ></BloggerContainer>
                    )}
                  </div>
                );
              })
            ) : (
              <div className="mt-m2">
                <Typography as="p" variant="body" className="font-medium">
                  No posts found
                  {currentQuery ? (
                    <>
                      {' '}
                      for <em>{currentQuery}</em>
                    </>
                  ) : (
                    ''
                  )}
                  .
                </Typography>
              </div>
            )}

            {!currentYear && !currentQuery && (
              <div className="mt-m1">
                <Typography as="div" variant="h3">
                  Read more from {contentfulComposePage?.title}
                </Typography>
                <Divider fullWidth className="mt-s2" />
                <div className="mt-s2 flex">
                  {currentSkip + limit < allBloggerPost?.edges?.length && (
                    <div className="flex">
                      <LocalizedLink
                        language={locale}
                        to={`${location.pathname}/?skip=${currentSkip + limit}`}
                        className="flex items-center"
                      >
                        <ArrowBack className="mr-s2" />

                        <Typography variant="body" className="font-medium">
                          See older posts
                        </Typography>
                      </LocalizedLink>
                    </div>
                  )}
                  {currentSkip !== 0 && (
                    <div className="ml-auto">
                      <LocalizedLink
                        language={locale}
                        to={`${location.pathname}/${
                          currentSkip - limit !== 0
                            ? `?skip=${currentSkip - limit}`
                            : ``
                        }`}
                        className="flex items-center"
                      >
                        <Typography variant="body" className="font-medium">
                          See newer posts
                        </Typography>
                        <ArrowForward className="ml-s2" />
                      </LocalizedLink>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="relative -mx-s3 mt-m2 bg-greyLight py-s4 px-s3 lg:hidden">
              {insideEdgeArchivesElement}
            </div>
          </div>

          <div className="hidden relative lg:block lg:col-span-3">
            <InsideEdgeSideColumn>
              <InsideEdgeSideColumnSticky className="bg-greyLight py-s4 px-s3">
                {insideEdgeSearchSignUpElement}
                <div className="mt-m2">{insideEdgeArchivesElement}</div>
              </InsideEdgeSideColumnSticky>
            </InsideEdgeSideColumn>
          </div>
        </div>
      </main>
      <Footer
        data={pageFooter}
        logo={contentfulComposePage?.website?.siteAssets?.logo}
        socialLinks={contentfulComposePage?.website?.siteAssets?.socialLinks}
      />

      {cookiePreferences && (
        <CookiePreferences
          id={cookiePreferences?.id}
          title={cookiePreferences?.title}
          description={cookiePreferences?.description}
          manageConsentPreferencesTitle={
            cookiePreferences?.manageConsentPreferencesTitle
          }
          cookieDuration={cookiePreferences?.cookieDuration}
          cookies={cookiePreferences?.cookies}
          acceptAllCookiesButton={cookiePreferences?.acceptAllCookiesButton}
          rejectAllCookiesButton={cookiePreferences?.rejectAllCookiesButton}
          manageCookiePreferencesButton={
            cookiePreferences?.manageCookiePreferencesButton
          }
          acceptChoosenPreferencesButton={
            cookiePreferences?.acceptChoosenPreferencesButton
          }
          location={location}
        />
      )}
    </Layout>
  );
};

export default Page;
